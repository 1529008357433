import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  return (
    <>
      <div
        className="d-flex pt-5 flex-column align-items-center text-center"
        style={{ width: "min(450px, 90% )" }}
      >
        <div style={{ fontSize: "4em" }} className="title-font">
          SEQ Breakup
        </div>
        <strong style={{ fontSize: "1.5em" }} className="title-font">
          Winter Wonderland
        </strong>

        <br />
        <br />
        <div
          className="d-flex pt-5 flex-column align-items-center text-center"
          style={
            {
              backdropFilter: "blur(10px)",
              "-webkit-backdrop-filter": "blur(10px)",
              borderRadius: "15px",
              width: "min(400px, 85% )",
            } as any
          }
        >
          <strong style={{ fontSize: "1.1em" }}>26th Nov 2022</strong>
          <br />
          <br />
          $5 Entry Fee
          <span>4pm Start</span>
          <span>
            <a
              className="text-white"
              href="https://goo.gl/maps/xfsgsEfWTRjpgi7b8"
            >
              Sunshine Coast Hall
            </a>
          </span>
          <br />
          <br />
          <br />
          Dinner & Supper Provided
          <br />
          <br />
          <br />
          <Link to="/register" className="">
            <Button
              style={{ fontSize: "1.7em" }}
              className="btn-cta title-font"
            >
              REGISTER HERE
            </Button>
          </Link>
          <br />
          <br />
        </div>
        <div className="flex-grow-1" />
      </div>
      <Footer />
    </>
  );
};

export default Home;
