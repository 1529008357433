import { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div
        className="font-plain text-white w-100"
        style={{
          fontSize: "0.8em",
          backdropFilter: "blur(10px)",
          "-webkit-backdrop-filter": "blur(10px)",
        }}
      >
        <div className="d-flex justify-content-center mx-auto align-items-center ">
          <div className="d-flex justify-content-center  align-items-center">
            SEQ Youngies{" "}
            <span className="mx-3" style={{ fontSize: "2em" }}>
              •
            </span>
          </div>
          <div>&copy; Revival Fellowship 2022</div>
        </div>
      </div>
    );
  }
}

export default Footer;
